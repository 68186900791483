import React from 'react'
import { graphql } from 'gatsby'
import { Box, useMediaQuery } from '@mui/material'
import { Footer, Header, SEO } from '@/components'

const rulesData = [
  'iMile（以下或称 “我们”）非常注重保护用户（以下或称“您”）的个人信息，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。我们希望通过本隐私政策向您清晰地介绍在使用我们的产品/服务时，我们如何处理您的个人信息，以及我们为您提供的访问、更正、删除和保护这些信息的方式。',
  '本政策将帮助您了解以下内容：',
  '1、我们如何收集和使用您的个人信息 ',
  '2、我们如何使用 Cookie和同类技术 ',
  '3、我们如何共享、转让、公开披露您的个人信息 ',
  '4、我们如何保存和保护您的个人信息',
  '5、您如何管理您的个人信息',
  '6、通知和修订',
  '7、如何联系我们',
  '8、附录',
]

const tooltipList = [
  '如对本隐私政策有任何疑问，您可以通过本隐私政策“如何联系我们”中提供的方式与我们联系。',
  '我们的产品/服务是由及其关联方提供者通过下述途径向您提供的产品/服务：包括但不限于App等客户端以及相关微信开放平台账号或小程序等。针对某些特定的产品/服务，我们还将制定单独的隐私政策，向您说明这些产品/服务的特殊政策。如相关特定的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。',
  '本政策所称的美团优选及其关联方是有限公司及现有和/或未来设立的相关关联公司的单称或合称。',
  '请您注意，您应保证在使用相关的服务中所提供的一切数据信息均为真实，且经过合法途径获得，如其中包含第三方个人信息，请确认您已取得该信息所有方的充分、合法的授权，一旦您在使用我们服务的过程中提供此类信息，我们将认为该类信息均为您有权处理，不存在任何违反法律、法规或侵犯任何第三方合法权利的事由 /瑕疵 / 场景。本政策不适用于您通过我们的产品 / 服务而接入的其他第三方产品 / 服务 ( “其他第三方”，包括但不限于您的交易相对方、任何第三方网站以及第三方服务提供者 ) ，具体规定请参照该第三方的隐私政策或类似声明。',
]

const howToGetCookies = [
  '在您使用我们的产品/服务时，您需要/可以选择授权我们收集和使用个人信息的场景包括：',
  ' 1、为了向您提供我们产品/服务的基本功能，您需要授权我们收集、使用必要信息的情形，如您拒绝提供前述必要信息，您将无法正常使用我们的产品/服务；',
  '2、为了向您提供我们产品/服务的拓展功能，您可以选择授权我们收集、使用信息的情形，如您拒绝提供前述信息，您将无法正常使用相关附加功能或无法实现我们拟达到的功能效果，但并不会影响您正常使用我们产品/服务的基本功能。',
  '（一）您需要授权我们收集和使用个人信息的场景',
  '我们会遵循正当、合法、必要的原则，出于本政策所述的下列目的收集和使用您的个人信息。',
  '1、帮助您成为我们的用户',
  '在您注册成为我们的用户时，您需要至少提供 手机号码 以创建账号，并完善相关的 网络身份识别信息（如头像、昵称及登录密码等） 。',
  '2 、帮助您完成身份认证 ',
  '为了帮您完成身份认证， 您需要在平台进行实名认证，并向我们提供账号信息（手机号码及口令等）、网络身份识别信息（如头像、姓名、网格站 ID等）、实名身份信息（如身份证、驾驶证等载明您实名身份的照片、复印件、号码、您的照片或者人脸识别进行认证等）、健康信息（如健康证等）、紧急联系人信息（紧急联系人姓名、手机号码、地址等）、车辆及行车信息等（如车辆、行驶证信息、驾驶证信息、车辆保险信息、车辆运输证信息、公司信息等）、工作地点（所在城市、区域、站点位置）等信息。',
  '您在 产品过程中所提交的任何文字、照片、视频等各种形式的信息，也可能会包含或者关联到您的个人信息， 我们将对于上述信息中涉及的个人信息予以严格保护。',
  '在实名认证的场景下，物流将向合作的第三方提交您的前述信息，并从合法保存有您个人信息的第三方处获得、保存有关您身份信息的验证结果 。',
  '3 、为您提供订单管理及 协助您完成物流配送服务',
]

const otherAgreements = [
  '为了方便您在线进行订单管理，更好地完成物流配送服务， 在您完成运输过程中，我们 可能会 收集您的订单信息、位置信息、浏览及搜索信息、以及图片、文字信息等，具体包括 ：',
  '（ 1 ） 运输管理相关信息， 包括装卸货地点、所需承运车辆规格（车牌号、车辆尺寸等）、装卸货时间、货物类型 / 名称信息、上传货物图片、上传回单、签收信息、退货取回地点、退货取回数量、物料交接数量、差异原因、备注信息及与配送货物相关的其他信息。',
  '（ 2 ） 沟通信息， 包括您在完成货物运输订单过程中通过平台发送的图片、文字信息内容及接打的电话，我们将通过信息记录的方式留存上述信息 ，以便发生纠纷时， 核实事实并处理。 4 、 向您提供基于地理位置的信息展示 ',
  '（ 1 ）位置信息 。 为了保障您能够正常使用并完成相应配送服务 ， 我们会收集您的位置信息、设备所在位置信息（如基于 WiFi、GPS、蓝牙、基站、传感器信息来判断您的位置）、日志信息、订单信息、网络信息、浏览及搜索信息等，并为您提供查询团点信息、支持团点位置导航的服务。',
  '（2）日志信息 。当您使用我们提供的产品/服务时，我们会自动收集您使用我们服务的详细情况，并作为有关的网络日志保存，包括您的 IP地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、及您请求的网页记录等信息 。',
  '5、 向您提供客服和售后服务',
]

const titleSx = {
  fontWeight: '600',
  color: '#000417',
}

export default (props) => {
  const isPc = useMediaQuery('@media (min-width: 800px)')

  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box sx={{ paddingTop: isPc ? '90px' : '30px' }}>
        <Box
          sx={{
            padding: isPc ? '50px 100px' : '50px 16px',
            fontSize: '12px',
            color: '#525566',
            h1: {
              textAlign: 'center',
              mb: '34px',
              ...titleSx,
            },
            h2: {
              ...titleSx,
              margin: '20px 0',
            },
          }}
        >
          <h1>隐私协议</h1>
          <Box>
            {rulesData.map((item) => (
              <Box sx={{ margin: '10px 0' }}>{item}</Box>
            ))}
          </Box>
          <Box sx={titleSx}>
            【特别提示】请您在使用我们提供的各项产品/服务前，仔细阅读并充分理解本《隐私政策》（重点内容我们已将字体加粗请您特别关注）并作出相应选择
            。
            一旦您使用或继续使用我们的产品/服务时，即意味着您同意我们按照本隐私政策处理您的相关信息。
          </Box>
          <Box>
            {tooltipList.map((item) => (
              <Box sx={{ margin: '10px 0' }}>{item}</Box>
            ))}
          </Box>
          <h2>一、我们如何收集和使用您的个人信息</h2>
          <Box>
            {howToGetCookies.map((item, idx) => (
              <Box
                sx={{
                  fontWeight: idx === 6 ? '600' : 'initial',
                  color: idx === 6 ? '#000417' : '#525566',
                  margin: '10px 0',
                }}
              >
                {item}
              </Box>
            ))}
          </Box>
          <h2>二 、第三方终端用户的授权同意</h2>
          <Box>
            {otherAgreements.map((item) => (
              <Box sx={{ margin: '10px 0' }}>{item}</Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["howToApply"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
